export function getEnum<T extends Record<string, string | number>>(
  errorEnum: T,
  errorKey: string | undefined,
): T[keyof T] {
  return errorKey && isInEnum(errorEnum, errorKey) ? errorEnum[errorKey as keyof T] : errorEnum["UNKNOWN" as keyof T];
}

export function isInEnum<T extends Record<string, string | number>>(enumObj: T, value: string): boolean {
  return Object.values(enumObj).includes(value as T[keyof T]);
}

export enum FieldsErrorEnum {
  REQUIRED_FIELD = "REQUIRED_FIELD",
  LUXON_DATE_TIME_INVALID = "LUXON_DATE_TIME_INVALID",
  INVALID_EMAIL_ADDRESS = "INVALID_EMAIL_ADDRESS",
  EMAIL_REQUIRED = "EMAIL_REQUIRED",
  UNKNOWN = "UNKNOWN",
}

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, Grid, Skeleton, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "./translations";

import { CreateIndexRequest, IndexIdentifier, SprengnetterIndexResponse } from "@/api/sprengConnectApi";
import { ChevronDownIcon } from "@/assets";
import { FormControlAutocomplete, FormControlTextField } from "@/components";
import { useGetErrorMessage } from "@/hooks";
import { createIndexRequestSchema } from "@/validation/indexValidation";

import { DocumentView } from "./DocumentView";

const defaultIndexDetails: CreateIndexRequest = {
  title: "",
  shortTitle: "",
  sprengnetterProdcutLandingpageLink: "",
  infoText: "",
  type: "",
  history: "",
  updates: "",
  lag: "",
  dataBasisLabel: "",
  methodLabel: "",
  geographicOutputLevelLabel: "",
  objectTypesLabel: "",
  identifier: IndexIdentifier.SIM,
};

type IndicesDetailsProps = {
  selectedIndex?: SprengnetterIndexResponse;
  newIndexMode?: boolean;
  onDeleteIndex: (selectedInstitutionId?: string) => void;
  onSubmitIndex: (data: CreateIndexRequest) => void;
  isLoading: boolean;
  isError: boolean;
};

export const IndicesDetails = ({
  selectedIndex,
  newIndexMode,
  onDeleteIndex,
  onSubmitIndex,
  isLoading,
  isError,
}: IndicesDetailsProps): JSX.Element | undefined => {
  const { t } = useTranslation("BackOfficeIndices");
  const getErrorMessage = useGetErrorMessage();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm<CreateIndexRequest>({
    mode: "all",
    resolver: yupResolver<CreateIndexRequest>(createIndexRequestSchema),
    defaultValues: defaultIndexDetails,
  });

  const fieldsAreDisabled = !selectedIndex && !newIndexMode;

  useEffect(() => {
    if (newIndexMode || !selectedIndex) {
      reset({
        ...defaultIndexDetails,
      });
      return;
    }
    if (selectedIndex) {
      reset({
        ...selectedIndex,
      });
    }
  }, [reset, selectedIndex, newIndexMode]);

  return (
    <Box>
      <Box
        padding={1}
        component="form"
        width={"100%"}
        onSubmit={handleSubmit((data) => {
          onSubmitIndex(data);
        })}
      >
        <Typography variant="pageSectionTitle">{t("IndicesDetails.title")}</Typography>
        {isError && <Alert severity="error">{t("error", { context: newIndexMode ? "create" : "update" })}</Alert>}
        {isLoading ? (
          <Box padding={2}>
            <Typography variant="body1">{t("loading", { context: newIndexMode ? "create" : "update" })}</Typography>
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" sx={{ mt: 1 }} />
            <Skeleton variant="rectangular" sx={{ mt: 1 }} />
          </Box>
        ) : (
          <Box padding={2}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControlTextField
                  control={control}
                  name="title"
                  label={t("IndicesDetails.title")}
                  errorMsg={getErrorMessage(errors.title)}
                  disabled={fieldsAreDisabled}
                  sx={{ marginBottom: "10px", width: "100%" }}
                  fullWidth
                />
                <FormControlTextField
                  control={control}
                  name="shortTitle"
                  label={t("IndicesDetails.shortTitle")}
                  errorMsg={getErrorMessage(errors.shortTitle)}
                  disabled={fieldsAreDisabled}
                  sx={{ marginBottom: "10px", width: "100%" }}
                  fullWidth
                />
                <FormControlTextField
                  control={control}
                  name="sprengnetterProdcutLandingpageLink"
                  label={t("IndicesDetails.sprengnetterProdcutLandingpageLink")}
                  errorMsg={getErrorMessage(errors.sprengnetterProdcutLandingpageLink)}
                  disabled={fieldsAreDisabled}
                  sx={{ marginBottom: "10px", width: "100%" }}
                  fullWidth
                />
                <FormControlTextField
                  control={control}
                  name="infoText"
                  label={t("IndicesDetails.infoText")}
                  errorMsg={getErrorMessage(errors.infoText)}
                  disabled={fieldsAreDisabled}
                  sx={{ marginBottom: "10px", width: "100%" }}
                  fullWidth
                />
                <FormControlTextField
                  control={control}
                  name="objectTypesLabel"
                  label={t("IndicesDetails.objectTypesLabel")}
                  errorMsg={getErrorMessage(errors.objectTypesLabel)}
                  disabled={fieldsAreDisabled}
                  sx={{ marginBottom: "10px", width: "100%" }}
                  fullWidth
                  placeholder={t("IndicesDetails.objectTypesLabelPlaceholder")}
                />
                <FormControlTextField
                  control={control}
                  name="geographicOutputLevelLabel"
                  label={t("IndicesDetails.geographicOutputLevelLabel")}
                  errorMsg={getErrorMessage(errors.geographicOutputLevelLabel)}
                  disabled={fieldsAreDisabled}
                  sx={{ marginBottom: "10px", width: "100%" }}
                  fullWidth
                  placeholder={t("IndicesDetails.geographicOutputLevelLabelPlaceholder")}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlTextField
                  control={control}
                  name="lag"
                  label={t("IndicesDetails.lag")}
                  errorMsg={getErrorMessage(errors.lag)}
                  disabled={fieldsAreDisabled}
                  sx={{ marginBottom: "10px", width: "100%" }}
                  fullWidth
                  placeholder={t("IndicesDetails.lagPlaceholder")}
                />
                <FormControlTextField
                  control={control}
                  name="methodLabel"
                  label={t("IndicesDetails.methodLabel")}
                  errorMsg={getErrorMessage(errors.methodLabel)}
                  disabled={fieldsAreDisabled}
                  sx={{ marginBottom: "10px", width: "100%" }}
                  fullWidth
                  placeholder={t("IndicesDetails.methodLabelPlaceholder")}
                />
                <FormControlTextField
                  control={control}
                  name="history"
                  label={t("IndicesDetails.history")}
                  errorMsg={getErrorMessage(errors.history)}
                  disabled={fieldsAreDisabled}
                  sx={{ marginBottom: "10px", width: "100%" }}
                  fullWidth
                  placeholder={t("IndicesDetails.historyPlaceholder")}
                />
                <FormControlTextField
                  control={control}
                  name="dataBasisLabel"
                  label={t("IndicesDetails.dataBasisLabel")}
                  errorMsg={getErrorMessage(errors.dataBasisLabel)}
                  disabled={fieldsAreDisabled}
                  sx={{ marginBottom: "10px", width: "100%" }}
                  fullWidth
                  placeholder={t("IndicesDetails.dataBasisLabelPlaceholder")}
                />
                <FormControlTextField
                  control={control}
                  name="updates"
                  label={t("IndicesDetails.updates")}
                  errorMsg={getErrorMessage(errors.updates)}
                  disabled={fieldsAreDisabled}
                  sx={{ marginBottom: "10px", width: "100%" }}
                  fullWidth
                  placeholder={t("IndicesDetails.updatesPlaceholder")}
                />
                <FormControlAutocomplete
                  control={control}
                  name="identifier"
                  label={t("IndicesDetails.identifier")}
                  options={Object.values(IndexIdentifier)}
                  getOptionLabel={(option) => t(`IndicesDetails.identifiers.${option}`)}
                  errorMsg={getErrorMessage(errors.identifier)}
                  mapValue={(value) => (value ? IndexIdentifier[value] : IndexIdentifier.SIM)}
                  mapChangedValue={(value) => (value ? IndexIdentifier[value] : IndexIdentifier.SIM)}
                  disabled={fieldsAreDisabled}
                  sx={{
                    width: "100%",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    lineHeight: "19px",
                    fontWeight: "700",
                    border: "none",
                    marginBottom: "10px",
                    color: (theme) => theme.palette.primary.main,
                    backgroundColor: (theme) => theme.palette.secondary.main,
                  }}
                  popupIcon={<ChevronDownIcon sx={{ m: "15px", height: "10px", width: "10px" }} />}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ height: "100%" }} label={t("IndicesDetails.identifier")} />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box padding={2}>
          <Button variant="contained" color="primary" type="submit" disabled={!isDirty || isLoading}>
            {t("IndicesDetails.save")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onDeleteIndex(selectedIndex?.id)}
            sx={{ marginLeft: "20px" }}
            disabled={fieldsAreDisabled || isLoading}
          >
            {t("IndicesDetails.delete")}
          </Button>
        </Box>
      </Box>
      {selectedIndex && (
        <Box padding={2}>
          <DocumentView indexId={selectedIndex.id} />
        </Box>
      )}
    </Box>
  );
};

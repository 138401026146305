import { Box, Button, IconButton, List, ListItemButton, Skeleton, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./translations";

import { IndexLinkedDocumentResponse, IndexLinkedDocumentSortByParameter, SortDirection } from "@/api/sprengConnectApi";
import { useGetIndexLinkedDocumentsQuery } from "@/api/sprengConnectApi/controllers/backOfficeController";
import { CloseIcon } from "@/assets";

type DocumentListProps = {
  selectedIndexId: string;
  selectedDocument?: IndexLinkedDocumentResponse;
  onDocumentSelected: (index: IndexLinkedDocumentResponse) => void;
  onCreateNewDocument?: () => void;
};

export const DocumentList = ({
  selectedIndexId,
  selectedDocument,
  onDocumentSelected,
  onCreateNewDocument,
}: DocumentListProps): JSX.Element => {
  const { t } = useTranslation("BackOfficeIndices");

  const [search, setSearch] = useState("");

  const { data, isLoading, isFetching, isError } = useGetIndexLinkedDocumentsQuery({
    ...(search != null && search !== "" && { name: search }),
    indexId: selectedIndexId,
    sortDirection: SortDirection.Asc,
    sortBy: IndexLinkedDocumentSortByParameter.Name,
  });

  if (isError) {
    return <Typography>{t("DocumentsList.error")}</Typography>;
  }

  return (
    <Box padding={1}>
      <Typography variant="pageSectionTitle">{t("DocumentsList.title")}</Typography>
      <Box padding={2}>
        <TextField
          label={t("DocumentsList.search")}
          variant="outlined"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          sx={{ backgroundColor: "white" }}
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => {
                  setSearch("");
                }}
              >
                <CloseIcon />
              </IconButton>
            ),
          }}
        />
        {onCreateNewDocument && (
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateNewDocument}
            fullWidth
            sx={{
              mt: "20px",
              mb: "20px",
            }}
          >
            {t("DocumentsList.newDocument")}
          </Button>
        )}
        {isLoading ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Box>
        ) : !(data?.data?.length ?? 0) ? (
          <Typography>{t("DocumentsList.noDocuments")}</Typography>
        ) : (
          <List>
            {data?.data?.map((document) => (
              <ListItemButton
                key={document.id}
                selected={selectedDocument?.id === document.id}
                onClick={() => onDocumentSelected(document)}
                sx={isFetching ? { opacity: 0.5 } : {}}
              >
                {document.name}
              </ListItemButton>
            ))}
          </List>
        )}
      </Box>
    </Box>
  );
};

import { RouteObject } from "react-router-dom";

import { handleDynamicImportError } from "@/helpers/errorHelpers";
import { ErrorPage, LoginPage } from "@/pages";
import { RequireOne } from "@/types";

// some types not exported by react-router-dom :-(
export type ImmutableRouteKey = "lazy" | "caseSensitive" | "path" | "id" | "index" | "children";

export type LazyFunctionReturnType = RequireOne<Omit<RouteObject, ImmutableRouteKey>>;

export const routes: RouteObject[] = [
  {
    path: "/",
    lazy: createLazyRoute(import("../components").then((module) => ({ default: module.AppBaseLayout }))),
    children: [
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "test",
        lazy: createLazyPagesRoute((module) => module.TestPage),
      },
      {
        path: "landing",
        lazy: createLazyPagesRoute((module) => module.LandingPage),
      },
      {
        path: "portfolio-analysis",
        lazy: createLazyPagesRoute((module) => module.PortfolioAnalysisPage),
      },
      {
        path: "playground",
        lazy: createLazyPagesRoute((module) => module.PlaygroundPage),
      },
      {
        path: "*",
        lazy: createLazyPagesRoute((module) => module.NotFoundPage),
      },
    ],
  },
  {
    path: "/backoffice",
    lazy: createLazyRoute(import("../components").then((module) => ({ default: module.AppBaseLayout }))),
    children: [
      {
        path: "login",
        lazy: createLazyPagesRoute((module) => module.AdminLoginPage),
      },
      {
        path: "institution",
        lazy: createLazyPagesRoute((module) => module.InstitutionPage),
      },
      {
        path: "user",
        lazy: createLazyPagesRoute((module) => module.UserPage),
      },
      {
        path: "subscription",
        lazy: createLazyPagesRoute((module) => module.SubscriptionPage),
      },
      {
        path: "user-history",
        lazy: createLazyPagesRoute((module) => module.UserHistoryPage),
      },
      {
        path: "indices",
        lazy: createLazyPagesRoute((module) => module.IndicesPage),
      },
      {
        path: "*",
        lazy: createLazyPagesRoute((module) => module.NotFoundPage),
      },
    ],
  },
];

type LazyComponent = () => Promise<LazyFunctionReturnType>;

function createLazyPagesRoute(
  component: (module: typeof import("../pages")) => () => JSX.Element | undefined,
): LazyComponent {
  return createLazyRoute(import("../pages").then((module) => ({ default: component(module) })));
}

function createLazyRoute(importFunc: Promise<{ default: () => JSX.Element | undefined }>): LazyComponent {
  return async () => {
    try {
      const Component = await importFunc;
      return {
        element: <Component.default />,
      };
    } catch (error) {
      handleDynamicImportError(error);
      console.error("Error loading component", error);
      return {
        element: <ErrorPage />,
      };
    }
  };
}

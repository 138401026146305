import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "./translations";

import { CreateInstitutionRequest, InstitutionResponse } from "@/api/sprengConnectApi";
import { InstitutionStatus } from "@/api/sprengConnectApi/types/backOfficeController/InstitutionStatus";
import { CheckboxField, FormControlTextField } from "@/components";
import { useGetErrorMessage } from "@/hooks";
import { createInstitutionRequestSchema } from "@/validation/institutionValidation";

const defaultInstitutionDetails: CreateInstitutionRequest = {
  name: "",
  identificationNumber: "",
  street: "",
  houseNumber: "",
  zipCode: "",
  city: "",
  country: "",
  additionalAddressInformation: "",
  status: InstitutionStatus.Active,
};

type InstitutionDetailsProps = {
  selectedInstitution?: InstitutionResponse;
  newInstitutionMode?: boolean;
  onCancelEditInstitution: (selectedInstitutionId?: string) => void;
  onSubmitInstitution: (data: CreateInstitutionRequest) => void;
  isLoading: boolean;
  isError: boolean;
};

export const InstitutionDetails = ({
  selectedInstitution,
  newInstitutionMode,
  onCancelEditInstitution,
  onSubmitInstitution,
  isLoading,
  isError,
}: InstitutionDetailsProps): JSX.Element | undefined => {
  const { t } = useTranslation("BackOfficeInstitution");
  const getErrorMessage = useGetErrorMessage();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<CreateInstitutionRequest>({
    mode: "all",
    resolver: yupResolver<CreateInstitutionRequest>(createInstitutionRequestSchema),
    defaultValues: defaultInstitutionDetails,
    // values: institutionDetails,
  });

  const institutionStatus = watch("status");
  const fieldsAreDisabled = !selectedInstitution && !newInstitutionMode;

  useEffect(() => {
    if (newInstitutionMode || !selectedInstitution) {
      reset({
        ...defaultInstitutionDetails,
      });
      return;
    }
    if (selectedInstitution) {
      reset({
        ...selectedInstitution,
      });
    }
  }, [reset, selectedInstitution, newInstitutionMode]);

  return (
    <Box
      padding={1}
      component="form"
      onSubmit={handleSubmit((data) => {
        onSubmitInstitution(data);
      })}
    >
      <Typography variant="pageSectionTitle">{t("InstitutionDetails.title")}</Typography>
      {isError && <Alert severity="error">{t("error", { context: newInstitutionMode ? "create" : "update" })}</Alert>}
      {isLoading ? (
        <Box padding={2}>
          <Typography variant="body1">{t("loading", { context: newInstitutionMode ? "create" : "update" })}</Typography>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" sx={{ mt: 1 }} />
          <Skeleton variant="rectangular" sx={{ mt: 1 }} />
        </Box>
      ) : (
        <Box padding={2}>
          <FormControlTextField
            control={control}
            name="name"
            label={t("InstitutionDetails.name")}
            errorMsg={getErrorMessage(errors.name)}
            disabled={fieldsAreDisabled}
            sx={{ marginBottom: "20px", width: "100%" }}
            fullWidth
          />
          <FormControlTextField
            control={control}
            name="identificationNumber"
            label={t("InstitutionDetails.identificationNumber")}
            errorMsg={getErrorMessage(errors.identificationNumber)}
            disabled={fieldsAreDisabled}
            sx={{ marginBottom: "20px", width: "100%" }}
            fullWidth
          />
          <FormControlTextField
            control={control}
            name="street"
            label={t("InstitutionDetails.street")}
            errorMsg={getErrorMessage(errors.street)}
            disabled={fieldsAreDisabled}
            sx={{ marginBottom: "20px", width: "100%" }}
            fullWidth
          />
          <FormControlTextField
            control={control}
            name="houseNumber"
            label={t("InstitutionDetails.houseNumber")}
            errorMsg={getErrorMessage(errors.houseNumber)}
            disabled={fieldsAreDisabled}
            sx={{ marginBottom: "20px", width: "100%" }}
            fullWidth
          />
          <FormControlTextField
            control={control}
            name="city"
            label={t("InstitutionDetails.city")}
            errorMsg={getErrorMessage(errors.city)}
            disabled={fieldsAreDisabled}
            sx={{ marginBottom: "20px", width: "100%" }}
            fullWidth
          />
          <FormControlTextField
            control={control}
            name="zipCode"
            label={t("InstitutionDetails.zipCode")}
            errorMsg={getErrorMessage(errors.zipCode)}
            disabled={fieldsAreDisabled}
            sx={{ marginBottom: "20px", width: "100%" }}
            fullWidth
          />
          <FormControlTextField
            control={control}
            name="country"
            label={t("InstitutionDetails.country")}
            errorMsg={getErrorMessage(errors.country)}
            disabled={fieldsAreDisabled}
            sx={{ marginBottom: "20px", width: "100%" }}
            fullWidth
          />
          <FormControlTextField
            control={control}
            name="additionalAddressInformation"
            label={t("InstitutionDetails.addressAdditional")}
            errorMsg={getErrorMessage(errors.additionalAddressInformation)}
            disabled={fieldsAreDisabled}
            sx={{ marginBottom: "20px", width: "100%" }}
            fullWidth
          />
          <CheckboxField
            checked={institutionStatus === InstitutionStatus.Active}
            label={t("InstitutionDetails.status")}
            name="institutionStatus"
            onChange={(_e, checked) =>
              setValue("status", checked ? InstitutionStatus.Active : InstitutionStatus.Inactive, { shouldDirty: true })
            }
            sx={{ marginBottom: "20px" }}
            disabled={fieldsAreDisabled}
          />
        </Box>
      )}
      <Box padding={2}>
        <Button variant="contained" color="primary" type="submit" disabled={!isDirty || isLoading}>
          {t("InstitutionDetails.save")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => (selectedInstitution ? onCancelEditInstitution(selectedInstitution.id) : undefined)}
          sx={{ marginLeft: "20px" }}
          disabled={fieldsAreDisabled || isLoading}
        >
          {t("InstitutionDetails.cancel")}
        </Button>
      </Box>
    </Box>
  );
};

import { ObjectSchema, object, string } from "yup";

import { CreateIndexLinkedDocumentRequest } from "@/api/sprengConnectApi";

export const createDocumentRequestSchema: ObjectSchema<CreateIndexLinkedDocumentRequest> = object().shape({
  name: string().required("REQUIRED_FIELD"),
  extension: string().required("REQUIRED_FIELD"),
  link: string().required("REQUIRED_FIELD"),
  indexId: string().required("REQUIRED_FIELD"),
});

import { Autocomplete } from "@mui/material";
import type { AutocompleteProps, AutocompleteRenderInputParams, ChipTypeMap } from "@mui/material";
import { ReactElement, useCallback } from "react";

import { TextFieldWithError } from "..";

export interface AutocompleteWithErrorProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>, "renderInput"> {
  label?: string;
  errorMsg?: string;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

export const AutocompleteWithError = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
>({
  label,
  errorMsg,
  renderInput,
  ...autocompleteProps
}: AutocompleteWithErrorProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>): ReactElement => {
  const defaultRenderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextFieldWithError label={label} errorMsg={errorMsg} variant="standard" {...params} />
    ),
    [label, errorMsg],
  );

  return <Autocomplete renderInput={renderInput ?? defaultRenderInput} {...autocompleteProps} />;
};

import { ObjectSchema, mixed, object, string } from "yup";

import { CreateIndexRequest, IndexIdentifier } from "@/api/sprengConnectApi";

export const createIndexRequestSchema: ObjectSchema<CreateIndexRequest> = object().shape({
  title: string().required("REQUIRED_FIELD"),
  shortTitle: string().required("REQUIRED_FIELD"),
  sprengnetterProdcutLandingpageLink: string().required("REQUIRED_FIELD"),
  infoText: string().required("REQUIRED_FIELD"),
  type: string().required("REQUIRED_FIELD"),
  history: string().required("REQUIRED_FIELD"),
  updates: string().required("REQUIRED_FIELD"),
  lag: string().required("REQUIRED_FIELD"),
  dataBasisLabel: string().required("REQUIRED_FIELD"),
  methodLabel: string().required("REQUIRED_FIELD"),
  geographicOutputLevelLabel: string().required("REQUIRED_FIELD"),
  objectTypesLabel: string().required("REQUIRED_FIELD"),
  identifier: mixed<IndexIdentifier>()
    .oneOf([IndexIdentifier.IPI, IndexIdentifier.IPN, IndexIdentifier.SEPI, IndexIdentifier.SIM])
    .required("REQUIRED_FIELD"),
});

import { Box, Button, Divider, Link, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./translations";

import { SprengnetterLogo } from "@/assets";
import { useAuthentication } from "@/hooks/useAuthentication";

import { RegistrationButton } from "./RegistrationButton";

const PASSWORD = import.meta.env.VITE_LOCAL_PASSWORD;

export const LoginPage = (): JSX.Element => {
  const { t } = useTranslation("LoginPage");
  const [, setAuthentication] = useAuthentication();
  const navigate = useNavigate();

  const [loginCode, setLoginCode] = useState("");

  const onLogin = (event: React.FormEvent): void => {
    event.preventDefault();
    if (loginCode === PASSWORD) {
      setAuthentication(true);
      navigate("/landing");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", height: "100%", width: "100%" }}>
      <Box
        sx={{
          heigth: "100%",
          width: "38%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: (theme) => theme.palette.common.white,
        }}
      >
        <SprengnetterLogo sx={{ width: "244px", height: "60px", mt: "25%" }} />
        <Typography sx={{ padding: "10%" }} variant="welcome">
          {t("welcome")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            mt: "auto",
            mb: 2,
          }}
        >
          <Link
            variant="welcomeFooter"
            href="https://www.sprengnetter.de/"
            sx={{
              color: "#4A4A49",
              textDecoration: "none",
              mx: 1,
              "&:hover": {
                color: "#4A4A49",
              },
            }}
          >
            {t("sprengnetter")}
          </Link>
          <Link
            variant="welcomeFooter"
            href="https://www.sprengnetter.de/datenschutz/"
            sx={{
              color: "#4A4A49",
              textDecoration: "none",
              mx: 1,
              "&:hover": {
                color: "#4A4A49",
              },
            }}
          >
            {t("dataProtection")}
          </Link>
          <Link
            variant="welcomeFooter"
            href="https://www.sprengnetter.de/impressum-von-sprengnetter/"
            sx={{
              color: "#4A4A49",
              textDecoration: "none",
              mx: 1,
              "&:hover": {
                color: "#4A4A49",
              },
            }}
          >
            {t("imprint")}
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          heigth: "100%",
          width: "62%",
          backgroundImage: "url(/LoginBackground.png)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "640px",
            height: "735px",
            borderRadius: "10px",
            backgroundColor: (theme) => theme.palette.common.white,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", padding: "24px", alignItems: "center" }}>
            <Typography variant="loginHeader">{t("loginHeader")}</Typography>
            <Typography variant="loginRegistrationDescription" sx={{ padding: "30px" }}>
              {t("loginDescription")}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", flexDirection: "column", width: "381px" }}>
                <Typography variant="loginFieldLabel">{t("emailAddress")}</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  sx={{
                    background: (theme) => theme.palette.invited.main,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: (theme) => theme.palette.invited.main,
                      },
                      "&:hover fieldset": {
                        borderColor: (theme) => theme.palette.invited.main,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: (theme) => theme.palette.invited.main,
                      },
                    },
                  }}
                  placeholder={t("yourEmailAddress")}
                />
              </Box>
              <Button variant="contained" sx={{ height: "44px", mt: "20px" }}>
                {t("sendLoginCodeByEmail")}
              </Button>
              <Typography variant="loginFieldLabel" sx={{ mt: "20px" }}>
                {t("loginCode")}
              </Typography>
              <Box
                sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                component={"form"}
                onSubmit={onLogin}
              >
                <TextField
                  variant="outlined"
                  type="password"
                  fullWidth
                  value={loginCode}
                  onChange={(e) => setLoginCode(e.target.value)}
                  sx={{
                    mr: "10px",
                    background: (theme) => theme.palette.invited.main,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: (theme) => theme.palette.invited.main,
                      },
                      "&:hover fieldset": {
                        borderColor: (theme) => theme.palette.invited.main,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: (theme) => theme.palette.invited.main,
                      },
                    },
                  }}
                  placeholder={t("yourLoginCode")}
                  size="small"
                />
                <Button variant="contained" sx={{ width: "100%", height: "44px" }} fullWidth type="submit">
                  {t("login")}
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "407px",
                justifyContent: "center",
                alignItems: "center",
                padding: "25px",
                mt: "20px",
              }}
            >
              <Divider sx={{ color: "black", width: "50%" }} />
              <Typography variant="welcomeOr" sx={{ ml: "10px", mr: "10px" }}>
                {t("or")}
              </Typography>
              <Divider sx={{ color: "black", width: "50%" }} />
            </Box>
            <Typography variant="loginRegistrationDescription" sx={{ padding: "30px" }}>
              {t("registerDescription")}
            </Typography>
            <RegistrationButton />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

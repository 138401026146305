import { FormControl, FormControlProps } from "@mui/material";
import { ReactElement } from "react";
import { Controller } from "react-hook-form";
import type { Control, FieldPath, FieldValues } from "react-hook-form";

import { TextFieldWithError } from "../../inputsWithError";
import type { TextFieldWithErrorProps } from "../../inputsWithError";

export type FormControlTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext = unknown,
> = TextFieldWithErrorProps & {
  name: TName;
  control: Control<TFieldValues, TContext>;
  errorMsg?: string;
  optional?: boolean;
  sx?: FormControlProps["sx"];
};

export const FormControlTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext = unknown,
>({
  name,
  control,
  errorMsg,
  optional,
  sx,
  ...textFieldProps
}: FormControlTextFieldProps<TFieldValues, TName, TContext>): ReactElement => (
  <FormControl error={Boolean(errorMsg)} sx={sx}>
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value = "" } }) => {
        return (
          <TextFieldWithError
            fullWidth
            value={value}
            onChange={onChange}
            errorMsg={errorMsg}
            optional={optional}
            {...textFieldProps}
          />
        );
      }}
    />
  </FormControl>
);

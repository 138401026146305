import { TOptions } from "i18next";
import { FieldError, Merge } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FieldsErrorEnum, getEnum } from "@/i18n/translations";

export const useGetErrorMessage = (): ((
  errorField: FieldError | Merge<FieldError, unknown> | undefined,
  options?: TOptions,
) => string) => {
  const { t: tCommon } = useTranslation();
  const getErrorMessage = (
    errorField: FieldError | Merge<FieldError, unknown> | undefined,
    options?: TOptions,
  ): string =>
    errorField?.message ? tCommon(`errors.fields.${getEnum(FieldsErrorEnum, errorField?.message)}`, options) : "";
  return getErrorMessage;
};

import { useCallback } from "react";

import { setAuthenticated } from "@/pages/applicationMainSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

export const useAuthentication = (): [boolean | undefined, (authenticated: boolean) => void] => {
  const isAuthenticated = useAppSelector((state) => state.applicationMain.authenticated);
  const dispatch = useAppDispatch();

  const setAuthentication = useCallback(
    (authenticated: boolean): void => {
      if (authenticated === isAuthenticated) return;
      dispatch(setAuthenticated(authenticated));
    },
    [isAuthenticated, dispatch],
  );

  return [isAuthenticated, setAuthentication];
};

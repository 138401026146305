import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "./translations";

import { CreateIndexLinkedDocumentRequest, IndexLinkedDocumentResponse } from "@/api/sprengConnectApi";
import { FormControlTextField } from "@/components";
import { useGetErrorMessage } from "@/hooks";
import { createDocumentRequestSchema } from "@/validation/createDocumentRequestSchema";

const defaultDocumentDetails: CreateIndexLinkedDocumentRequest = {
  name: "",
  extension: "",
  link: "",
  indexId: "",
};

type DocumentDetailsProps = {
  selectedIndexId: string;
  selectedDocument?: IndexLinkedDocumentResponse;
  newDocumentMode?: boolean;
  onDeleteDocument: (selectedInstitutionId?: string) => void;
  onSubmitDocument: (data: CreateIndexLinkedDocumentRequest) => void;
  isLoading: boolean;
  isError: boolean;
};

export const DocumentDetails = ({
  selectedIndexId,
  selectedDocument,
  newDocumentMode,
  onDeleteDocument,
  onSubmitDocument,
  isLoading,
  isError,
}: DocumentDetailsProps): JSX.Element | undefined => {
  const { t } = useTranslation("BackOfficeIndices");
  const getErrorMessage = useGetErrorMessage();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm<CreateIndexLinkedDocumentRequest>({
    mode: "all",
    resolver: yupResolver<CreateIndexLinkedDocumentRequest>(createDocumentRequestSchema),
    defaultValues: {
      ...defaultDocumentDetails,
      indexId: selectedIndexId,
    },
  });

  const fieldsAreDisabled = !selectedDocument && !newDocumentMode;

  useEffect(() => {
    if (newDocumentMode || !selectedDocument) {
      reset({
        ...defaultDocumentDetails,
        indexId: selectedIndexId,
      });
      return;
    }
    if (selectedDocument) {
      reset({
        ...selectedDocument,
      });
    }
  }, [reset, selectedDocument, newDocumentMode, selectedIndexId]);

  return (
    <Box
      padding={1}
      component="form"
      onSubmit={handleSubmit((data) => {
        onSubmitDocument(data);
      })}
    >
      <Typography variant="pageSectionTitle">{t("IndicesDetails.title")}</Typography>
      {isError && <Alert severity="error">{t("error", { context: newDocumentMode ? "create" : "update" })}</Alert>}
      {isLoading ? (
        <Box padding={2}>
          <Typography variant="body1">{t("loading", { context: newDocumentMode ? "create" : "update" })}</Typography>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" sx={{ mt: 1 }} />
          <Skeleton variant="rectangular" sx={{ mt: 1 }} />
        </Box>
      ) : (
        <Box padding={2}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControlTextField
                control={control}
                name="name"
                label={t("DocumentDetails.name")}
                errorMsg={getErrorMessage(errors.name)}
                disabled={fieldsAreDisabled}
                sx={{ marginBottom: "10px", width: "100%" }}
                fullWidth
              />
              <FormControlTextField
                control={control}
                name="extension"
                label={t("DocumentDetails.extension")}
                errorMsg={getErrorMessage(errors.extension)}
                disabled={fieldsAreDisabled}
                sx={{ marginBottom: "10px", width: "100%" }}
                fullWidth
              />
              <FormControlTextField
                control={control}
                name="link"
                label={t("DocumentDetails.link")}
                errorMsg={getErrorMessage(errors.link)}
                disabled={fieldsAreDisabled}
                sx={{ marginBottom: "10px", width: "100%" }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box padding={2}>
        <Button variant="contained" color="primary" type="submit" disabled={!isDirty || isLoading}>
          {t("DocumentDetails.save")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onDeleteDocument(selectedDocument?.id)}
          sx={{ marginLeft: "20px" }}
          disabled={fieldsAreDisabled || isLoading}
        >
          {t("DocumentDetails.delete")}
        </Button>
      </Box>
      <Box padding={2}></Box>
    </Box>
  );
};

import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./translations";

const email = "kreditwirtschaft@sprengnetter.de";

export const RegistrationButton = (): JSX.Element => {
  const { t } = useTranslation("LoginPage");

  const handleEmailClick = (): void => {
    const subject = t("emailRegistrationSubject");
    const body = t("emailRegistrationBody");

    const mailto = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailto;
  };

  return (
    <Button variant="contained" color="secondary" sx={{ mt: "24px", width: "381px" }} onClick={handleEmailClick}>
      {t("sendRegistrationRequest")}
    </Button>
  );
};

import { FormHelperText, TextField, type TextFieldProps } from "@mui/material";

import { InputError } from "../../inputError";

export type TextFieldWithErrorProps = Omit<TextFieldProps, "sx"> & {
  errorMsg?: string;
  optional?: boolean;
  textFieldSx?: TextFieldProps["sx"];
};

export const TextFieldWithError = ({
  errorMsg,
  optional,
  textFieldSx,
  ...textfieldProps
}: TextFieldWithErrorProps): JSX.Element => {
  return (
    <>
      <TextField variant="outlined" error={Boolean(errorMsg)} sx={textFieldSx} {...textfieldProps} />
      {optional && <FormHelperText color="info">Optional</FormHelperText>}
      {errorMsg && <InputError errorMsg={errorMsg} />}
    </>
  );
};

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, Skeleton, TextField, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "./translations";

import { useGetRolesQuery } from "@/api/sprengConnectApi/controllers/backOfficeController";
import {
  CreateUserRequest,
  RoleSortByParameter,
  SortDirection,
  UserResponse,
  UserStatus,
} from "@/api/sprengConnectApi/types/backOfficeController";
import { ChevronDownIcon } from "@/assets";
import { CheckboxField, FormControlAutocomplete, FormControlDatePicker, FormControlTextField } from "@/components";
import { useGetErrorMessage } from "@/hooks";
import { createUserRequestSchema } from "@/validation/userValidation";

const defaultUser: CreateUserRequest = {
  firstName: "",
  lastName: "",
  email: "",
  status: UserStatus.ACTIVE,
  roleId: "",
  institutionId: "",
};

type UserDetailsProps = {
  institutionId: string;
  selectedUser?: UserResponse;
  newUserMode?: boolean;
  onDeleteUser: (selectedUserId?: string) => void;
  isLoading: boolean;
  isError: boolean;
  onSubmitUser: (data: CreateUserRequest) => void;
};

export const UserDetails = ({
  selectedUser,
  newUserMode,
  onDeleteUser,
  isLoading,
  isError,
  onSubmitUser,
  institutionId,
}: UserDetailsProps): JSX.Element | undefined => {
  const { t } = useTranslation("BackOfficeUser");
  const getErrorMessage = useGetErrorMessage();

  const {
    data: rolesData,
    isLoading: rolesLoading,
    isFetching: rolesFetching,
    isError: rolesError,
  } = useGetRolesQuery({
    sortBy: RoleSortByParameter.Name,
    sortDirection: SortDirection.Asc,
  });

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<CreateUserRequest>({
    mode: "all",
    resolver: yupResolver<CreateUserRequest>(createUserRequestSchema),
    defaultValues: { ...defaultUser, roleId: rolesData?.data[0].id, institutionId },
  });

  useEffect(() => {
    if (newUserMode) {
      reset({ ...defaultUser, roleId: rolesData?.data[0].id, institutionId });
    } else if (selectedUser) {
      reset(selectedUser);
    }
  }, [newUserMode, reset, selectedUser, rolesData, institutionId]);

  const userStatus = watch("status");
  const allRoles = rolesData?.data ?? [];

  console.log(errors);

  return (
    <Box
      padding={1}
      component="form"
      onSubmit={handleSubmit((data) => {
        onSubmitUser(data);
      })}
    >
      <Typography variant="pageSectionTitle">{t("UserDetails.title")}</Typography>
      {isError && <Alert severity="error">{t("error", { context: newUserMode ? "create" : "update" })}</Alert>}
      {isLoading ? (
        <Box padding={2}>
          <Typography variant="body1">{t("loading", { context: newUserMode ? "create" : "update" })}</Typography>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" sx={{ mt: 1 }} />
          <Skeleton variant="rectangular" sx={{ mt: 1 }} />
        </Box>
      ) : (
        <Box padding={2}>
          <FormControlTextField
            control={control}
            name="firstName"
            label={t("UserDetails.firstName")}
            errorMsg={getErrorMessage(errors.firstName)}
            disabled={!selectedUser && !newUserMode}
            sx={{ marginBottom: "20px", width: "100%" }}
            fullWidth
          />
          <FormControlTextField
            control={control}
            name="lastName"
            label={t("UserDetails.lastName")}
            errorMsg={getErrorMessage(errors.lastName)}
            disabled={!selectedUser && !newUserMode}
            sx={{ marginBottom: "20px", width: "100%" }}
            fullWidth
          />
          <FormControlTextField
            control={control}
            name="email"
            label={t("UserDetails.email")}
            errorMsg={getErrorMessage(errors.email)}
            disabled={!selectedUser && !newUserMode}
            sx={{ marginBottom: "20px", width: "100%" }}
            fullWidth
          />
          <CheckboxField
            checked={userStatus === UserStatus.ACTIVE}
            label={t("UserDetails.status")}
            name="userStatus"
            onChange={(_e, checked) =>
              setValue("status", checked ? UserStatus.ACTIVE : UserStatus.INACTIVE, { shouldDirty: true })
            }
            sx={{ marginBottom: "20px" }}
            disabled={!selectedUser && !newUserMode}
          />
          {rolesLoading || rolesFetching ? (
            <Skeleton variant="text" />
          ) : rolesError ? (
            <Typography variant="body1">{t("UserDetails.roleError")}</Typography>
          ) : (
            <FormControlAutocomplete
              control={control}
              name="roleId"
              label={t("UserDetails.subscriptions")}
              options={rolesData?.data ?? []}
              getOptionLabel={(option) => option.name}
              errorMsg={getErrorMessage(errors.roleId)}
              mapValue={(value) => allRoles.find((role) => role.id === value) ?? null}
              mapChangedValue={(value) => value?.id ?? allRoles[0].id}
              disabled={!selectedUser && !newUserMode}
              sx={{
                height: "100%",
                width: "100%",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontSize: "14px",
                lineHeight: "19px",
                fontWeight: "700",
                border: "none",
                marginBottom: "20px",
                color: (theme) => theme.palette.primary.main,
                backgroundColor: (theme) => theme.palette.secondary.main,
              }}
              popupIcon={<ChevronDownIcon sx={{ m: "15px", height: "10px", width: "10px" }} />}
              renderInput={(params) => (
                <TextField {...params} sx={{ height: "100%" }} label={t("UserDetails.subscriptions")} />
              )}
            />
          )}
          <FormControlDatePicker
            control={control}
            name="expirationDate"
            label={t("UserDetails.expirationDate")}
            errorMsg={getErrorMessage(errors.expirationDate)}
            disabled={!selectedUser && !newUserMode}
            sx={{ width: "100%" }}
            defaultValue={DateTime.now().plus({ days: 1 })}
          />
        </Box>
      )}
      <Box padding={2}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isDirty || (!selectedUser && !newUserMode)}
        >
          {t("UserDetails.save")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onDeleteUser(selectedUser?.id)}
          sx={{ marginLeft: "20px" }}
          disabled={!selectedUser && !newUserMode}
        >
          {t("UserDetails.delete")}
        </Button>
      </Box>
    </Box>
  );
};

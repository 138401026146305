import { ObjectSchema, mixed, object, string } from "yup";

import { CreateInstitutionRequest } from "@/api/sprengConnectApi/types/backOfficeController";
import { InstitutionStatus } from "@/api/sprengConnectApi/types/backOfficeController/InstitutionStatus";

export const createInstitutionRequestSchema: ObjectSchema<CreateInstitutionRequest> = object().shape({
  name: string().required("REQUIRED_FIELD"),
  identificationNumber: string().required("REQUIRED_FIELD"),
  street: string().required("REQUIRED_FIELD"),
  houseNumber: string().required("REQUIRED_FIELD"),
  zipCode: string().required("REQUIRED_FIELD"),
  city: string().required("REQUIRED_FIELD"),
  country: string().required("REQUIRED_FIELD"),
  additionalAddressInformation: string().optional(),
  status: mixed<InstitutionStatus>()
    .oneOf([InstitutionStatus.Active, InstitutionStatus.Inactive])
    .required("REQUIRED_FIELD"),
});

import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./translations";

import { CreateIndexLinkedDocumentRequest, IndexLinkedDocumentResponse } from "@/api/sprengConnectApi";
import {
  useCreateIndexLinkedDocumentMutation,
  useDeleteIndexLinkedDocumentMutation,
  useUpdateIndexLinkedDocumentMutation,
} from "@/api/sprengConnectApi/controllers/backOfficeController";
import { ConfirmDialog } from "@/components";

import { DocumentDetails } from "./DocumentDetails";
import { DocumentList } from "./DocumentList";

type DocumentViewProps = {
  indexId: string;
};

export const DocumentView = ({ indexId }: DocumentViewProps): JSX.Element => {
  const { t } = useTranslation("BackOfficeIndices");

  const [selectedDocument, setSelectedDocument] = useState<IndexLinkedDocumentResponse | undefined>();
  const [newDocumentMode, setNewDocumentMode] = useState<boolean>(false);
  const [deleteDocumentDialogOpen, setDeleteDocumentDialogOpen] = useState<boolean>(false);

  const [
    createDocument,
    { isError: isCreateDocumentError, isLoading: isCreateDocumentLoading, isSuccess: isCreateDocumentSuccess },
  ] = useCreateIndexLinkedDocumentMutation();
  const [
    updateDocument,
    { isError: isUpdateDocumentError, isLoading: isUpdateDocumentLoading, isSuccess: isUpdateDocumentSuccess },
  ] = useUpdateIndexLinkedDocumentMutation();
  const [
    deleteDocument,
    { isError: isDeleteDocumentError, isLoading: isDeleteDocumentLoading, isSuccess: isDeleteDocumentSuccess },
  ] = useDeleteIndexLinkedDocumentMutation();

  useEffect(() => {
    if (isCreateDocumentSuccess || isUpdateDocumentSuccess || isDeleteDocumentSuccess) {
      setSelectedDocument(undefined);
      setNewDocumentMode(false);
    }
  }, [isCreateDocumentSuccess, isUpdateDocumentSuccess, isDeleteDocumentSuccess]);

  const onDeleteDocument = (): void => {
    setSelectedDocument(undefined);
    setNewDocumentMode(false);
  };

  const onCreateNewDocument = (): void => {
    setSelectedDocument(undefined);
    setNewDocumentMode(true);
  };

  const onDocumentSelected = (document: IndexLinkedDocumentResponse): void => {
    setSelectedDocument(document);
    setNewDocumentMode(false);
  };

  const onSubmitDocument = (data: CreateIndexLinkedDocumentRequest): void => {
    if (newDocumentMode) {
      createDocument(data);
      return;
    }
    if (!selectedDocument) return;
    updateDocument({
      documentId: selectedDocument.id,
      request: data,
    });
  };

  return (
    <Box width={"100%"}>
      <ConfirmDialog
        open={deleteDocumentDialogOpen}
        title={t("DeleteDocumentDialog.title")}
        cancelText={t("DeleteDocumentDialog.cancel")}
        confirmText={t("DeleteDocumentDialog.delete")}
        message={t("DeleteDocumentDialog.message")}
        onDecision={(decision) => {
          setDeleteDocumentDialogOpen(false);

          if (decision && selectedDocument) deleteDocument(selectedDocument.id);
          onDeleteDocument();
        }}
      />
      <Grid container sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
        <Grid item xs={6}>
          <DocumentList
            selectedIndexId={indexId}
            selectedDocument={selectedDocument}
            onDocumentSelected={onDocumentSelected}
            onCreateNewDocument={onCreateNewDocument}
          />
        </Grid>
        <Grid item xs={6}>
          <DocumentDetails
            selectedIndexId={indexId}
            selectedDocument={selectedDocument}
            onDeleteDocument={() => setDeleteDocumentDialogOpen(true)}
            newDocumentMode={newDocumentMode}
            onSubmitDocument={onSubmitDocument}
            isLoading={isCreateDocumentLoading || isUpdateDocumentLoading || isDeleteDocumentLoading}
            isError={isCreateDocumentError || isUpdateDocumentError || isDeleteDocumentError}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

import { FormControl } from "@mui/material";
import type { AutocompleteValue, ChipTypeMap, FormControlProps } from "@mui/material";
import { ReactElement } from "react";
import { Controller } from "react-hook-form";
import type { Control, FieldPath, FieldValues, PathValue } from "react-hook-form";

import { AutocompleteWithError } from "../../inputsWithError";
import type { AutocompleteWithErrorProps } from "../../inputsWithError";

export interface FormControlAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext = unknown,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> extends Exclude<AutocompleteWithErrorProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>, "sx"> {
  name: TName;
  control: Control<TFieldValues, TContext>;
  mapChangedValue?: (
    value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
  ) => PathValue<TFieldValues, TName>;
  mapValue?: (value: PathValue<TFieldValues, TName>) => AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>;
  sx?: FormControlProps["sx"];
  autoCompleteSx?: AutocompleteWithErrorProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>["sx"];
}

export const FormControlAutocomplete = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext = unknown,
>({
  name,
  control,
  label,
  errorMsg,
  mapChangedValue,
  mapValue,
  fullWidth,
  sx,
  autoCompleteSx,
  ...autocompleteProps
}: FormControlAutocompleteProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo,
  TFieldValues,
  TName,
  TContext
>): ReactElement => {
  return (
    <FormControl error={Boolean(errorMsg)} fullWidth={fullWidth} sx={sx}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value: controllerValue } }) => {
          return (
            <AutocompleteWithError
              value={mapValue?.(controllerValue) ?? controllerValue}
              onChange={(_event, value) => onChange(mapChangedValue?.(value) ?? value)}
              label={label}
              errorMsg={errorMsg}
              sx={autoCompleteSx}
              {...autocompleteProps}
            />
          );
        }}
      />
    </FormControl>
  );
};

import { FormControl } from "@mui/material";
import { PickerValidDate } from "@mui/x-date-pickers";
import { ReactElement } from "react";
import { Controller } from "react-hook-form";
import type { Control, FieldPath, FieldValues } from "react-hook-form";

import { DatePickerWithError, DatePickerWithErrorProps } from "@/components/inputsWithError";

export interface FormControlDatePickerProps<
  TDate extends PickerValidDate,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext = unknown,
> extends DatePickerWithErrorProps<TDate> {
  name: TName;
  control: Control<TFieldValues, TContext>;
  errorMsg?: string;
  sx?: import("@mui/system").SxProps<import("@mui/material/styles").Theme>;
}

export const FormControlDatePicker = <
  TDate extends PickerValidDate,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext = unknown,
>({
  name,
  control,
  errorMsg,
  sx,
  ...props
}: FormControlDatePickerProps<TDate, TFieldValues, TName, TContext>): ReactElement => (
  <FormControl error={Boolean(errorMsg)} sx={sx}>
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value = null } }) => {
        return <DatePickerWithError value={value} onChange={onChange} errorMsg={errorMsg} {...props} />;
      }}
    />
  </FormControl>
);

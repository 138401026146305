import { DatePicker, type DatePickerProps, PickerValidDate } from "@mui/x-date-pickers";
import { ReactElement } from "react";

import { InputError } from "../../inputError";

export interface DatePickerWithErrorProps<TDate extends PickerValidDate> extends Exclude<DatePickerProps<TDate>, "sx"> {
  errorMsg?: string;
  datePickerSx?: DatePickerProps<TDate>["sx"];
}

export const DatePickerWithError = <TDate extends PickerValidDate>({
  errorMsg,
  datePickerSx,
  ...props
}: DatePickerWithErrorProps<TDate>): ReactElement => {
  return (
    <>
      <DatePicker
        slotProps={{
          textField: {
            error: Boolean(errorMsg),
          },
        }}
        orientation="landscape"
        sx={datePickerSx}
        {...props}
      />
      {errorMsg && <InputError errorMsg={errorMsg} />}
    </>
  );
};

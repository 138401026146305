import { ObjectSchema, mixed, object, string } from "yup";

import { CreateUserRequest, UserStatus } from "@/api/sprengConnectApi/types/backOfficeController";

import { luxonDateTimeSchema } from "./dateTimeValidation";

export const createUserRequestSchema: ObjectSchema<CreateUserRequest> = object().shape({
  firstName: string().min(3, "REQUIRED_FIELD").required("REQUIRED_FIELD"),
  lastName: string().min(3, "REQUIRED_FIELD").required("REQUIRED_FIELD"),
  email: string().min(3, "REQUIRED_FIELD").email("EMAIL_INVALID").required("REQUIRED_FIELD"),
  status: mixed<UserStatus>()
    .oneOf([UserStatus.ACTIVE, UserStatus.INACTIVE, UserStatus.DELETED])
    .required("REQUIRED_FIELD"),
  roleId: string().required("REQUIRED_FIELD"),
  expirationDate: luxonDateTimeSchema.optional(),
  institutionId: string().required("REQUIRED_FIELD"),
});
